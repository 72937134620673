import "./Links.css";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Links = () => {

    document.oncontextmenu = function() { return false; }
    
    const { token } = useParams();
    const [link, setLink] = useState({});
    const [viewStatus, setViewStatus] = useState("");

    useEffect(() => {
        getLink().then((response) => { console.log("getLinkUseEffect"); setLink(response); });
    }, []);

    useEffect(() => {
        getViewStatus();
    }, [link]);

    useEffect(() => {
        const checkDateInterval = setInterval(() => {
            checkDate();
        }, 30000);
        return () => clearInterval(checkDateInterval);
    }, [viewStatus]);

    useEffect(() => {
        const getLinkInterval = setInterval(() => {
            getLink().then((response) => { console.log("getLinkInterval"); setLink(response); });
        }, 300000);
        return () => clearInterval(getLinkInterval);
    }, [viewStatus]);

    async function getLink() {
        const response = await axios.get("https://api.alimentoparatualma.org/api/v1/link/" + token + "?by=token").then((response) => { console.log("getLinkAxios"); return response.data.data; }).catch((error) => { return error.response.data.data; });
        return response;
    }

    function getViewStatus() {
        let viewStatus = "loading";
        if (link.error !== undefined) {
            viewStatus = "error";
        } else if (link.id !== undefined) {
            if (link.status === "close") {
                viewStatus = "close";
            } else if (link.status === "open") {
                let dateStop = false;
                if (link.dateOpened !== null && link.dateClosed !== null) {
                    let dateToday = new Date();
                    let dateOpened = new Date(link.dateOpened);
                    let dateClosed = new Date(link.dateClosed);
                    if (dateToday < dateOpened) {
                        dateStop = true;
                        viewStatus = "preopen";
                    } else if (dateToday > dateClosed) {
                        dateStop = true;
                        viewStatus = "posopen";
                    }
                }
                if (dateStop === false) {
                    if (link.privacity === "private") {
                        let viewToken = localStorage.getItem("viewToken");
                        let viewPassword = localStorage.getItem("viewPassword");
                        if (viewToken === null || (viewToken !== null && viewToken !== token) || viewPassword === null || (viewPassword !== null && viewPassword === "password")) {
                            viewStatus = "password";
                        } else if (viewToken !== null && viewToken === token && viewPassword !== null && viewPassword === "player") {
                            viewStatus = "player";
                        }
                    } else if (link.privacity === "public") {
                        viewStatus = "player";
                    }
                }
            }
        }
        if (viewStatus != "loading") {
            if (viewStatus === "password" || viewStatus === "player") {
                localStorage.setItem("viewToken", token);
                localStorage.setItem("viewPassword", viewStatus);
            } else {
                localStorage.removeItem("viewToken");
                localStorage.removeItem("viewPassword");
            }
        }
        setViewStatus(viewStatus);
    }

    function checkPassword(event) {
        event.preventDefault();
        let password = event.target.password.value;
        if (password !== "") {
            if (password === link.password) {
                localStorage.setItem("viewPassword", "player");
                setViewStatus("player");
            } else {
                alert("La contraseña es incorrecta.");
                event.target.password.value = "";
            }
        } else {
            alert("Escribe la contraseña.");
        }
    }

    function checkDate() {
        let dateToday = new Date();
        if (viewStatus === "preopen") {
            let dateOpened = new Date(link.dateOpened);
            if (dateToday >= dateOpened) {
                getViewStatus();
            }
        } else if (viewStatus === "password" || viewStatus === "player") {
            if (link.dateClosed !== null) {
                let dateClosed = new Date(link.dateClosed);
                if (dateToday > dateClosed) {
                    getViewStatus();
                }
            }
        }
    }

    if (viewStatus === "loading") {
        return <section className="Links">
            <div className="loading">
                <FontAwesomeIcon icon="fa-solid fa-spinner" />
                <p>Buscando este link...</p>
            </div>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    } else if (viewStatus === "error") {
        return <section className="Links">
            <div className="error">
                <FontAwesomeIcon icon="fa-solid fa-circle-xmark" />
                <p>Este link no existe.</p>
            </div>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    } else if (viewStatus === "close") {
        return <section className="Links">
            <div className="close">
                <FontAwesomeIcon icon="fa-solid fa-ban" />
                <p>Este link ya no está disponible.</p>
            </div>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    } else if (viewStatus === "preopen") {
        return <section className="Links">
            <div className="preopen">
                <FontAwesomeIcon icon="fa-solid fa-clock" />
                <p>Este link abre el <strong>{link.dateOpened.split(" ")[0]}</strong> a las <strong>{link.dateOpened.split(" ")[1]}</strong> hrs.</p>
            </div>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    } else if (viewStatus === "posopen") {
        return <section className="Links">
            <div className="posopen">
                <FontAwesomeIcon icon="fa-solid fa-lock" />
                <p>Este link ya está cerrado.</p>
            </div>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    } else if (viewStatus === "password") {
        return <section className="Links">
            <form className="password" onSubmit={checkPassword}>
                <FontAwesomeIcon icon="fa-solid fa-lock" />
                <input type="password" name="password" placeholder="Escribe la contraseña." />
                <button>Desbloquear</button>
            </form>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    } else if (viewStatus === "player") {
        return <section className="Links">
            <article className="player">
                <header>
                    <p>"{link.preach.title}"</p>
                </header>
                <main>
                    {(link.preach.type === "video") ? <video src={link.preach.file} width="100%" controls controlsList="nodownload" /> : (link.preach.type === "audio") ? <audio src={link.preach.file} width="100%" controls /> : ""}
                </main>
                {(link.dateClosed !== null) ? <footer><p>Este link cierra el <strong>{link.dateClosed.split(" ")[0]}</strong> a las <strong>{link.dateClosed.split(" ")[1]}</strong> hrs.</p></footer> : ""}
            </article>
            <a href="https://youtube.com/@alimentoparatualmaorg" target="_blank"><FontAwesomeIcon icon="fa-brands fa-youtube" /> Visita nuestro canal en Youtube</a>
        </section>;
    }
}